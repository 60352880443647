<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <!-- Page title -->
                <div class="col-lg-12 text-center">
                    <h2 class="display-3 title">Праздник Труб 2007</h2>
                </div>
                <!-- Page title -->

                <!-- Page content -->
                <div class="col-lg-12">
                    <b-row>
                        <b-col />
                        <b-col>
                            <b-link :to="{path: '/photos/album/2'}" variant="info">Фото 1</b-link><br>
                            <b-link :to="{path: '/photos/album/3'}" variant="info">Фото 2</b-link><br><br>

                            <iframe width="560" height="315" src="https://www.youtube.com/embed/0YaCO79KjD4" frameborder="0" allowfullscreen /> 
                        </b-col>
                        <b-col />
                    </b-row>
                    <b-row>
                        <b-col>
                            <p>
                                В США украинско-российская диаспора устроила Праздник труб<br><br> 
 
                                Северо-Западное баптистское объединение США уже третий год подряд организовывает Праздник труб. На сей раз этот древнееврейский праздник состоялся 27 октября в городе Портланд (штат Орегон). Из разных городов и штатов съезжаются музыканты, чтобы славить Господа на духовых инструментах. В местную церковь “Суламита” уже с двух часов дня сходились оркестранты, неся в руках различные инструменты.<br><br> 
                            
                                Вначале такого необычного служения приветственным словом поделился ответственный и ведущий Кравчук Петр Иванович, пастор Брянской церкви г. Аубурн (Вашингтон). “Первый раз мы трубили в Спокене, потом в Сиэтле, сейчас трубим в Орегоне. Игра на духовых инструментах требует не только таланта и определенных навыков, но и физических сил. Поэтому я желаю оркестрантам не ослабевать в усердии”, - сказал ведущий.<br><br> 
                            
                                Сводный духовой оркестр, численностью до ста человек, исполнил величественный гимн “Христа да возвеличат все”, под руководством Алика Саволюка, дирижера оркестра первой украинской церкви.<br><br> 
                            
                                «Небо слышит звук трубы», - уверенно заявил пастор Сергей Куропатко из Спокена. “Ни для кого не секрет, что музыка призвана возрождать, она изменяет жизни и смягчает сердца. Чтобы достичь полной гармонии звука, оркестр повинуется дирижеру и взмаху его палочки, так и мы должны повиноваться Богу в нашей жизни”, - посоветовал брат Сергей.<br><br> 
                            
                                Оркестранты из каждого города исполнили по несколько гимнов, представляя свою программу. Все они были профессионально подготовленны и в хорошем настроении.<br><br> 
                            
                                Об исторической предпосылке для Праздника труб рассказал Сергей Павлович Чумакин, пастор славянской церкви “Спасение” (Эйджвуд, Вашингтон): “Согласно Левит 23:23, праздник труб, это один из великих еврейских праздников. Этот праздник имел духовное значение. Евреи отмечали его в первый день седьмого месяца. Когда звучали трубные звуки, евреи рисовали в своем воображении картину Святого Бога Саваофа, и в этот момент они проверяли перед Богом свои сердца. Когда звучал звук “шафара” , - сильный протяжный трубный звук, это было символом покаяния”.<br><br> 
                            
                                Дирижеры и оркестранты делились своими переживаниями. Утверждали, что Бог по-особенному любит звук трубы, Он часто вспоминает о трубном звуке в своем Слове, поэтому так часто дьявол пытался преграждать этому служению, но творческие люди - очень настойчивые, и поэтому, не смотря на все преграды, хвала для Бога будет звучать всегда, делились музыканты.<br><br> 
                            
                                «Хвалите Его со звуком трубным. Хвалите Его на псалтыри и на гуслях, хвалите Его с тимпаном и ликами, хвалите Его на струнах и органе, хвалите его на звучных кимвалах, и на кимвалах громогласных, все дышащее да хвалит Господа. Аллилуйя» (Пс. 150). Такими словами из Псалтыря закончилось праздничное богослужение, люди расходились переполненные эмоций, а увлеченные музыканты еще долго играли для славы достойного Бога.<br><br> 
                            
                                <a href="http://prochurch.info/index.php/news/more/10656">Источник: Юлия Марук, США, ProChurch.info</a>
                            </p>
                            <b-button :to="{name: 'prazdniktrub'}" variant="primary">Вернуться к Праздник труб</b-button>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {};
</script>